export const tableColumns = [
	{
		model: 'Remark',
		i18n: 'remark2317',
		sortable: true,
	},
	{
		model: 'BacklinkObjectId',
		i18n: 'oid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkObjectId__Text',
		},
	},
	{
		colType: 'link',
		model: 'BacklinkObjectName',
		i18n: 'object',
		sortable: true,
	},
	{
		model: 'BacklinkObjectValidTo',
		i18n: 'objectvalidunti',
		sortable: true,
		filter: 'daterange',
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkObjectValidTo__Text',
		},
	},
	{
		model: 'BacklinkWebLink',
		i18n: 'addedtothesite',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'BacklinkCount',
		i18n: 'numberoflinks',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'BacklinkState',
		i18n: 'status2825',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkState__Text',
		},
	},
	{
		model: 'BacklinkCreationDate',
		i18n: 'dateadded',
		sortable: true,
		filter: 'daterange',
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkCreationDate__Text',
		},
	},
	{
		model: 'BacklinkLastCheckDate',
		i18n: 'checked2831',
		sortable: true,
		filter: 'daterange',
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkLastCheckDate__Text',
		},
	},
	{
		model: 'BacklinkExpirationDate',
		i18n: 'linkvaliduntil',
		sortable: true,
		filter: 'daterange',
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkExpirationDate__Text',
		},
	},
	{
		colType: 'country',
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
	},
	{
		model: 'BacklinkType',
		i18n: 'type2877',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkType__Text',
		},
	},
	{
		model: 'BacklinkCategory',
		i18n: 'category2878',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'BacklinkCategory__Text',
		},
	},
];
