<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:customFilterDropdowns="filterDropdowns"
		:customHeadingTranslations="{ RemarkOwner: $t('colleague') }"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'Id', ascending: 0 }"
		modelId="Id"
		headerTitle="backlinks2822"
		tooltipMsg="manualforbackli"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="BacklinksTable"
	>
		<template #filter__Remark>
			<div class="d-flex flex-row align-items-center">
				<b-form-select
					@change="handleFilter()"
					v-if="filterDropdowns.Remark.length"
					v-model="filterModel.Remark"
					:options="filterDropdowns.Remark"
				></b-form-select>
				<label>&nbsp;&nbsp;</label>
				<b-form-select
					@change="handleFilter()"
					v-model="filterModel.RemarkOwner"
					:options="filterDropdowns.RemarkOwner"
				></b-form-select>
			</div>
		</template>

		<template #Remark="list">
			<div class="d-flex">
				<remark-indicate
					:entityId="list.row.Id"
					:entityTypeId="4"
					:remarkTypeId="1"
					:refreshMethod="tableGetData"
					:defaultRemark="list.row.Remark"
					:administratorList="administratorList"
				></remark-indicate>
			</div>
		</template>

		<template #BacklinkWebLink="list">
			<div v-for="item in list.row.Urls" :key="item" class="item">
				<a :href="item" target="_blank">{{ item }}</a>
			</div>
		</template>

		<template #BacklinkCount="list">
			<div class="d-flex">
				{{ list.row.BacklinkCount }}
				<note-display :value="list.row.BacklinkNote" v-if="list.row.BacklinkNote" class="ml-2" />
			</div>
		</template>

		<template #customActions="list">
			<email-send-dropdown
				:buttonText="$t('sendanoffer')"
				:pesLogTypeId="pesLogTypeId"
				:offersList="offersList"
				:entityId="Number(list.row.Id)"
				:withTable="false"
			></email-send-dropdown>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import { setDropdownOptions, setCellFormatOptions } from '@/components/general/utils';
import serviceEnums from '@/services/service/enums.service';
import servicePortalEmail from '../../modules/portal-emails/portal-email.service';
import apiUser from '../../services/api/user.api';
import { cloneDeep } from 'lodash';
import utils from '../../services/helpers/utils';
import enums from '../../services/helpers/enums';

import RemarkIndicate from '../../components/table/remark-indicate';
import EmailSendDropdown from '../../modules/emails/email-send.dropdown';
import NoteDisplay from '../../components/common/note-display';

import { tableColumns } from './backlink.table-data';

export default {
	name: 'BacklinkTable',

	components: {
		TablePage,
		RemarkIndicate,
		EmailSendDropdown,
		NoteDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'BacklinkListItem',
			columns: tableColumns,

			StateDict: serviceEnums.getEnumDict('backlinkState', true),
			TypeDict: serviceEnums.getEnumDict('backlinkType', true),
			CategoryDict: serviceEnums.getEnumDict('backlinkCategory', true),

			administratorList: [],

			filterDropdowns: {
				Remark: [],
				RemarkOwner: [],
			},

			pesLogTypeId: enums.sendLogsTypeEnum.Backlink,
			offersList: [],
		};
	},

	async created() {
		this.columns = this.prepareColumns(tableColumns);

		await Promise.all([this.getAdminAndRemarkList(), this.getEmailSubjects()]);
	},

	methods: {
		prepareColumns(columns) {
			let values = serviceEnums.getEnumForDropdown('backlinkState', true);
			columns = setDropdownOptions(columns, 'BacklinkState', values);

			values = serviceEnums.getEnumForDropdown('backlinkType', true);
			columns = setDropdownOptions(columns, 'BacklinkType', values);

			values = serviceEnums.getEnumForDropdown('backlinkCategory', true);
			columns = setDropdownOptions(columns, 'BacklinkCategory', values);

			columns = setCellFormatOptions(columns, 'BacklinkObjectName', {
				props: (list) => ({
					href: list.row.BacklinkObjectUrl,
					target: '_blank',
				}),
			});

			return columns;
		},

		async getAdminAndRemarkList() {
			let admins = await apiUser.getAdministratorList();
			this.administratorList = cloneDeep(admins.data);
			this.administratorList.unshift({ id: null, name: this.$i18n.t('unassigned') });
			admins.data.unshift({ id: -1, name: this.$i18n.t('unassigned') });
			const RemarkOwner = utils.convertItems(
				admins.data,
				function (item) {
					return { value: item.id, text: item.name };
				},
				{ value: null, text: '' }
			);
			const Remark = serviceEnums.getEnumForDropdown('remark', true);

			this.filterDropdowns = {
				Remark,
				RemarkOwner,
			};
		},

		formatType(type) {
			if (type > 0) {
				return this.TypeDict[type].Text;
			} else {
				return '-';
			}
		},
		formatObject(obj) {
			if (obj) {
				return 'O' + obj;
			} else {
				return '';
			}
		},

		formatCategory(cat) {
			if (cat > 0) {
				return this.CategoryDict[cat].Text;
			} else {
				return '-';
			}
		},

		formatState(state) {
			if (state > 0) {
				return this.StateDict[state].Text;
			} else {
				return '-';
			}
		},

		transformResponseData(data) {
			data.data = data.data.map((item) => ({
				...item,
				BacklinkObjectId__Text: item.BacklinkObjectId ? `O${item.BacklinkObjectId}` : '',
				BacklinkState__Text: item.BacklinkState > 0 ? this.StateDict[item.BacklinkState].Text : '-',
				BacklinkType__Text: item.BacklinkType > 0 ? this.TypeDict[item.BacklinkType].Text : '-',
				BacklinkCategory__Text: item.BacklinkCategory > 0 ? this.CategoryDict[item.BacklinkCategory].Text : '-',
				BacklinkObjectValidTo__Text: this.$options.filters.date(item.BacklinkObjectValidTo),
				BacklinkCreationDate__Text: this.$options.filters.date(item.BacklinkCreationDate),
				BacklinkLastCheckDate__Text: this.$options.filters.date(item.BacklinkLastCheckDate),
				BacklinkExpirationDate__Text: this.$options.filters.date(item.BacklinkExpirationDate),
			}));

			return data;
		},

		// refresh() {
		// 	this.tableKey += 1;
		// },

		redirectToDetail(itemId = 0) {
			this.$router.push({
				name: links.backlinkDetailName,
				params: { Id: itemId },
			});
		},

		async getEmailSubjects() {
			let emailIds = '64,65,66,67';
			const resp = await servicePortalEmail.getEmailSubjectsByIds(emailIds);
			this.offersList = resp.data;
		},
	},
};
</script>

<style lang="scss">
.BacklinksTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 100px;
		width: 100px;
	}
}
</style>
