var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "BacklinksTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          customFilterDropdowns: _vm.filterDropdowns,
          customHeadingTranslations: { RemarkOwner: _vm.$t("colleague") },
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "Id", ascending: 0 },
          modelId: "Id",
          headerTitle: "backlinks2822",
          tooltipMsg: "manualforbackli",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "filter__Remark",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-center" },
                    [
                      _vm.filterDropdowns.Remark.length
                        ? _c("b-form-select", {
                            attrs: { options: _vm.filterDropdowns.Remark },
                            on: {
                              change: function ($event) {
                                return _vm.handleFilter()
                              },
                            },
                            model: {
                              value: _vm.filterModel.Remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterModel, "Remark", $$v)
                              },
                              expression: "filterModel.Remark",
                            },
                          })
                        : _vm._e(),
                      _c("label", [_vm._v("  ")]),
                      _c("b-form-select", {
                        attrs: { options: _vm.filterDropdowns.RemarkOwner },
                        on: {
                          change: function ($event) {
                            return _vm.handleFilter()
                          },
                        },
                        model: {
                          value: _vm.filterModel.RemarkOwner,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterModel, "RemarkOwner", $$v)
                          },
                          expression: "filterModel.RemarkOwner",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "Remark",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("remark-indicate", {
                        attrs: {
                          entityId: list.row.Id,
                          entityTypeId: 4,
                          remarkTypeId: 1,
                          refreshMethod: _vm.tableGetData,
                          defaultRemark: list.row.Remark,
                          administratorList: _vm.administratorList,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "BacklinkWebLink",
              fn: function (list) {
                return _vm._l(list.row.Urls, function (item) {
                  return _c("div", { key: item, staticClass: "item" }, [
                    _c("a", { attrs: { href: item, target: "_blank" } }, [
                      _vm._v(_vm._s(item)),
                    ]),
                  ])
                })
              },
            },
            {
              key: "BacklinkCount",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _vm._v(
                        "\n\t\t\t" + _vm._s(list.row.BacklinkCount) + "\n\t\t\t"
                      ),
                      list.row.BacklinkNote
                        ? _c("note-display", {
                            staticClass: "ml-2",
                            attrs: { value: list.row.BacklinkNote },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _c("email-send-dropdown", {
                    attrs: {
                      buttonText: _vm.$t("sendanoffer"),
                      pesLogTypeId: _vm.pesLogTypeId,
                      offersList: _vm.offersList,
                      entityId: Number(list.row.Id),
                      withTable: false,
                    },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          2114165492
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }